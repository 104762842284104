{
  "ascending": "rastući",
  "ascendingSort": "sortirano po kolonama {columnName} rastućim redosledom",
  "columnSize": "{value} piksela",
  "descending": "padajući",
  "descendingSort": "sortirano po kolonama {columnName} padajućim redosledom",
  "resizerDescription": "Pritisnite Enter da biste započeli promenu veličine",
  "select": "Izaberite",
  "selectAll": "Izaberite sve",
  "sortable": "kolona koja se može sortirati"
}
