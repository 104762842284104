{
  "ascending": "遞增",
  "ascendingSort": "已依據「{columnName}」欄遞增排序",
  "columnSize": "{value} 像素",
  "descending": "遞減",
  "descendingSort": "已依據「{columnName}」欄遞減排序",
  "resizerDescription": "按 Enter 鍵以開始調整大小",
  "select": "選取",
  "selectAll": "全選",
  "sortable": "可排序的欄"
}
