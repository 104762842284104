{
  "ascending": "오름차순",
  "ascendingSort": "{columnName} 열을 기준으로 오름차순으로 정렬됨",
  "columnSize": "{value} 픽셀",
  "descending": "내림차순",
  "descendingSort": "{columnName} 열을 기준으로 내림차순으로 정렬됨",
  "resizerDescription": "크기 조정을 시작하려면 Enter를 누르세요.",
  "select": "선택",
  "selectAll": "모두 선택",
  "sortable": "정렬 가능한 열"
}
