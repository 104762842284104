{
  "ascending": "升序",
  "ascendingSort": "按列 {columnName} 升序排序",
  "columnSize": "{value} 像素",
  "descending": "降序",
  "descendingSort": "按列 {columnName} 降序排序",
  "resizerDescription": "按“输入”键开始调整大小。",
  "select": "选择",
  "selectAll": "全选",
  "sortable": "可排序的列"
}
